* {
  padding: 0;
  margin: 0;
  font-family: monospace;
}
.App {
  padding: 20px 10vw;
  min-height: 100vh;
}
.upload {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.upload > h1 {
  font-size: 2.5rem;
  color: darkblue;
}
.upload-area {
  position: relative;
}
.add {
  border: none;
  background: transparent;
  outline: none;
  color: blue;
  font-size: 1.5rem;
  position: absolute;
  height: 100%;
  bottom: 0;
  right: 0;
  padding: 10px;
  border-radius: 5px;
  font-weight: 700;
  cursor: pointer;
}
.add:disabled {
  color: red;
}
.gallery {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-auto-rows: 220px;
  grid-gap: 10px;
}
.gallery > a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}
.gallery > a > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: .3s ease;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.473);
}
.gallery > a > img:hover {
  transform: scale(110%);
  /* object-fit: contain; */
  border-radius: 10px;
}
.login-form {
  display: block;
}
.email, .pass {
  padding: 1px 5px;
  margin: 2px 4px;
}
.button {
  background: green;
}

@media (max-width: 500px) {
  .upload {
    display: block;
  }
  .upload > h1 {
    font-size: 3rem;
    margin-bottom: 10px;
  }
}